import React from "react";
import {
  contactContainer,
  titleHeader,
  mapContainer,
  contactInfoContainer,
} from "./contact.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const TitleHeader = ({ children }) => {
  return <h2 className={titleHeader}>{children}</h2>;
};

const ContactSection = () => {
  return (
    <div className={contactContainer}>
      <TitleHeader>¡Contactanos!</TitleHeader>
      <div className={mapContainer}>
        <iframe
          width="100%"
          height="100%"
          loading="lazy"
          allowfullscreen
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJqQUJpD2vKIQRjoUNCpcc8Hc&key=AIzaSyDgrzrphwp72CwLVVdeXdQ6kxdShWmqqVo"
        ></iframe>
      </div>
      <div className={contactInfoContainer}>
        <p>Av. Hidalgo #1750 tercer piso</p>
        <p>Col. Ladrón de Guevara</p>
        <p>C.P. 44600</p>
        <p>Guadalajara, Jalisco, México</p>
        <p>(33) 2267 2429</p>
        <p>(33) 2267 2430</p>
        <p>info@korall-medics.com</p>
      </div>
    </div>
  );
};

export default ContactSection;
