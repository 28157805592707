import React, { useRef, useEffect } from "react";

//Components
import Layout from "../components/Layout";
import ContactSection from "../components/Contact";

const Contact = () => {
  return (
    <Layout>
      <section className="contact-info">
        <ContactSection />
      </section>
    </Layout>
  );
};

export default Contact;
